import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import commonApiService from "../../common/js/common-api-service";
import commonPoService from "./common-po-service";
export default {
  name: "Purchase Order Entry/Exit Matrix",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      selectedProject: "",
      projectData: [],
      selectedBusiness: "",
      businessData: [],
      selectedMatrixStatus: "",
      matrixStatusList: [
        {
          text: "All",
          value: "A",
        },
        {
          text: "Active",
          value: "1",
        },
        {
          text: "Inactive",
          value: "0",
        },
      ],
      totalRecords: 0,
      matrixEntriesList: [],
      excelName: "",
      exportDisable: true,
      json_fields: {
        "Path#": "Path#",
        Direction: "Direction",
        Warehouse: "Warehouse",
        Location: "Location",
        "Cos Grade": "Cos Grade",
        "Created By": "Created By",
        "Created On": "On",
        Active: "active",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      headersMatrixEntries: [
        {
          text: "Active",
          value: "active",
          align: "start",
          class: "primary customwhite--text",
        },
        {
          text: "Path #",
          value: "Path#",
          class: "primary customwhite--text",
        },
        {
          text: "Direction",
          value: "Direction",
          class: "primary customwhite--text",
        },
        {
          text: "Warehouse",
          value: "Warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Location",
          value: "Location",
          class: "primary customwhite--text",
        },
        {
          text: "Cos Grade",
          value: "Cos Grade",
          class: "primary customwhite--text",
        },
        {
          text: "Created By",
          value: "Created By",
          class: "primary customwhite--text",
        },
        {
          text: "Created Date",
          value: "On",
          class: "primary customwhite--text",
        },
      ],
      projectSelected: true,
      businessSelected: true,
      directionList: [
        {
          text: "Inbound",
          value: "In",
        },
        {
          text: "Outbound",
          value: "Out",
        },
      ],
      addSelectedProject: "",
      addSelectedBusiness: "",
      addSelectedWarehouse: "",
      addSelectedLocation: "",
      addSelectedCosmeticGrade: "",
      warehouseData: [],
      locationData: [],
      cosGradeData: [],
      businessDataAdd: [],
      purchaseMatrixStatusChange: false,
      matrixOperationItem: [],
      matrixDirection: "",
      addMatrixOperation: false,
      disableProject: true,
      disableBusiness: true,
      disableWarehouse: true,
      disableLocation: true,
      disableCosmeticGrade: true,
      disableDirection: false,
      matrixNewDropdown: [],
      matrixDirectionAdd: "",
      addMatrixBtnDisable: true,
      searchMatrixOperations: "",
      enableSearch: true,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectData();
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.selectedBusiness = "";
      this.selectedMatrixStatus = "";
      this.totalRecords = 0;
      this.matrixEntriesList = [];
      this.excelName = "";
      this.exportDisable = true;
      this.projectSelected = true;
      this.businessSelected = true;
      this.purchaseMatrixStatusChange = false;
      this.matrixOperationItem = [];
      this.matrixDirection = "";
      this.matrixDirectionAdd = "";
      this.addMatrixOperation = false;
      this.enableSearch = true;
      this.getProjectData();
      this.closeDialog();
    },
    //To get project dropdown data
    async getProjectData() {
      let projData = await commonApiService.getProject("get", this.userId);
      if (projData !== null && projData !== undefined) {
        this.projectData = projData.ProjectInfo;
      }
    },
    //On project change
    async onProjChange() {
      let obj = {
        UserId: parseInt(this.userId),
        ProjKey: parseInt(this.selectedProject),
        BuKey: 0,
        IncludeEmptyBU: 0,
        WithinSameLocation: 0,
        loca_key: 0,
      };
      let busiData = await commonApiService.postBusinessData("post", obj);
      if (busiData !== null && busiData !== undefined) {
        this.selectedBusiness = "";
        this.selectedMatrixStatus = "";
        this.totalRecords = 0;
        this.matrixEntriesList = [];
        this.exportDisable = true;
        this.projectSelected = true;
        this.businessSelected = true;
        this.businessData = busiData;
        this.projectSelected = false;
        this.enableSearch = true;
      }
    },
    //On Business Change
    onBusinessChange() {
      this.businessSelected = false;
      this.enableSearch = true;
      this.selectedMatrixStatus = "";
    },
    //Search button click event
    async onSearch() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObj = {
        pass: 1,
        user_id: parseInt(this.userId),
        bu_id: this.selectedBusiness,
        status: this.selectedMatrixStatus,
        rm_id: 0,
        direction: "",
        ware_key: 0,
        location: "",
        cos_grade: "",
        active: true,
      };
      this.axios
        .post("po/po_matrix_operations", searchObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let responseData = JSON.parse(response.data.body.message);
            this.totalRecords = responseData.length;
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
                On: Utility.convertESTToLocal(obj.On),
              };
            });
            this.matrixEntriesList = newArr;
            if (this.totalRecords > 0) {
              this.exportDisable = false;
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.totalRecords = 0;
          this.matrixEntriesList = [];
          this.exportDisable = true;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Change status of the Matrix Operation
    changeMatrixStatus(item) {
      this.matrixOperationItem = [];
      if (item.active == true) {
        this.popupTitleParent = "Are you sure you want to disable this item?";
      } else if (item.active == false) {
        this.popupTitleParent = "Are you sure you want to enable this item?";
      }
      this.matrixOperationItem = item;
      this.purchaseMatrixStatusChange = true;
    },
    //Matrix Status Change API
    matrixStatus() {
      if (this.matrixOperationItem.Direction == "In") {
        this.matrixDirection = "R";
      } else if (this.matrixOperationItem.Direction == "Out") {
        this.matrixDirection = "S";
      }
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let updateObj = {
        pass: 3,
        user_id: parseInt(this.userId),
        bu_id: this.selectedBusiness,
        status: this.selectedMatrixStatus,
        rm_id: parseInt(this.matrixOperationItem.rm_id),
        direction: this.matrixDirection,
        ware_key: 0,
        location: this.matrixOperationItem.Location,
        cos_grade: this.matrixOperationItem["Cos Grade"],
        active: !this.matrixOperationItem.active,
      };
      this.axios
        .post("po/po_matrix_operations", updateObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.closeDialog();
            this.onSearch();
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //New item clicked
    async newItemClicked() {
      let dropObj = {
        pass: 2,
        user_id: parseInt(this.userId),
        bu_id: 0,
        status: "",
        rm_id: 0,
        direction: "",
        ware_key: 0,
        location: "",
        cos_grade: "",
        active: true,
      };
      let dropdownData = await commonPoService.matrixEntryOperations(
        "post",
        dropObj
      );
      if (dropdownData !== null && dropdownData !== undefined) {
        this.matrixNewDropdown = dropdownData;
        this.addMatrixOperation = true;
      }
    },
    //Direction in add popup
    addDirectionChanged() {
      this.disableProject = false;
      if (this.selectedDirection == "In") {
        this.matrixDirectionAdd = "R";
      } else if (this.selectedDirection == "Out") {
        this.matrixDirectionAdd = "S";
      }
      this.addSelectedProject = "";
      this.addSelectedBusiness = "";
      this.addSelectedWarehouse = "";
      this.addSelectedLocation = "";
      this.addSelectedCosmeticGrade = "";
      this.disableBusiness = true;
      this.disableWarehouse = true;
      this.disableLocation = true;
      this.disableCosmeticGrade = true;
      this.matrixNewDropdown = this.matrixNewDropdown.filter(
        (obj) => obj.direction === this.matrixDirectionAdd
      );
    },
    //On project change in Add popup
    async addProjectChanged() {
      let obj = {
        UserId: parseInt(this.userId),
        ProjKey: parseInt(this.addSelectedProject),
        BuKey: 0,
        IncludeEmptyBU: 0,
        WithinSameLocation: 0,
        loca_key: 0,
      };
      let busiData = await commonApiService.postBusinessData("post", obj);
      if (busiData !== null && busiData !== undefined) {
        this.businessDataAdd = busiData;
        this.disableBusiness = false;
        this.addSelectedBusiness = "";
        this.addSelectedWarehouse = "";
        this.addSelectedLocation = "";
        this.addSelectedCosmeticGrade = "";
        this.disableWarehouse = true;
        this.disableLocation = true;
        this.disableCosmeticGrade = true;
      }
    },
    //Business in add popup
    addBusinessChanged() {
      this.warehouseData = this.matrixNewDropdown.filter(
        (obj) => obj.bu_key === parseInt(this.addSelectedBusiness)
      );
      this.disableWarehouse = false;
      this.addSelectedWarehouse = "";
      this.addSelectedLocation = "";
      this.addSelectedCosmeticGrade = "";
      this.disableLocation = true;
      this.disableCosmeticGrade = true;
    },
    //Warehouse in add popup
    addWarehouseChanged() {
      this.locationData = this.warehouseData.filter(
        (obj) => obj.ware_key === this.addSelectedWarehouse
      );
      this.disableLocation = false;
      this.addSelectedLocation = "";
      this.addSelectedCosmeticGrade = "";
      this.disableCosmeticGrade = true;
    },
    //Location in add popup
    addLocationChanged() {
      this.cosGradeData = this.locationData.filter(
        (obj) => obj.location === this.addSelectedLocation
      );
      if (this.cosGradeData[0].cos_grade == "") {
        this.addMatrixBtnDisable = false;
      } else {
        this.disableCosmeticGrade = false;
        this.addSelectedCosmeticGrade = "";
      }
    },
    //File Name for Export functionality
    fileName() {
      this.excelName =
        "Purchase_Matrix_Operations" +
        new Date()
          .toISOString()
          .replace("T", "_")
          .replace("Z", "")
          .replace(".", "")
          .replaceAll("-", "_") +
        ".xls";
    },
    //Close Add popup
    closeDialog() {
      if (this.addMatrixOperation) {
        this.selectedDirection = "";
        this.addMatrixOperation = false;
        this.addSelectedProject = "";
        this.addSelectedBusiness = "";
        this.addSelectedWarehouse = "";
        this.addSelectedLocation = "";
        this.addSelectedCosmeticGrade = "";
        this.disableProject = true;
        this.disableBusiness = true;
        this.disableWarehouse = true;
        this.disableLocation = true;
        this.disableCosmeticGrade = true;
        this.disableDirection = false;
        this.addMatrixBtnDisable = true;
      } else if (this.purchaseMatrixStatusChange) {
        this.purchaseMatrixStatusChange = false;
        this.matrixOperationItem = [];
        this.onSearch();
      }
    },
    //Add New Matrix Entry
    addMatrixEntry() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let addObj = {
        pass: 6,
        user_id: parseInt(this.userId),
        bu_id: this.addSelectedBusiness,
        status: "1",
        rm_id: 0,
        direction: this.matrixDirectionAdd,
        ware_key: this.addSelectedWarehouse,
        location: this.addSelectedLocation,
        cos_grade: this.addSelectedCosmeticGrade,
        active: true,
      };
      this.axios
        .post("po/po_matrix_operations", addObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.closeDialog();
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.refreshMatrixDialog();
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Refresh button in New Matrix popup
    refreshMatrixDialog() {
      this.selectedDirection = "";
      this.addSelectedProject = "";
      this.addSelectedBusiness = "";
      this.addSelectedWarehouse = "";
      this.addSelectedLocation = "";
      this.addSelectedCosmeticGrade = "";
      this.disableProject = true;
      this.disableBusiness = true;
      this.disableWarehouse = true;
      this.disableLocation = true;
      this.disableCosmeticGrade = true;
      this.disableDirection = false;
      this.addMatrixBtnDisable = true;
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (
        this.actionList !== undefined &&
        this.actionList !== null &&
        this.actionList !== ""
      ) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
  },
};
